<template>
  <span>
    <FimLink
      :id="id"
      :to="localePath(item.href)"
      :data-prefetch="item.prefetch"
      badge-placement="top"
      text-size="md"
      only-exact-active
      class="my-1.5 border-b-2 border-transparent hover:border-black"
      @click="onLinkClick"
    >
      {{ item.label }}
    </FimLink>
  </span>
</template>

<script setup lang="ts">
import FimLink from '~/components/ui/links/FimLink.vue'
import useUiState from '~/composables/ui/useUiState'

const localePath = useFimLocalePath()
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  onLinkClick: {
    type: Function as PropType<(event: MouseEvent) => void>,
    required: true,
  },
})

const { flyoutMenuLink } = useUiState()
const id = computed(() =>
  formatId(`${flyoutMenuLink.value.slug}-${props.item?.label}`, 'menu-item'),
)
</script>
